export enum ManagerRoles {
  ConferenceServicesManager = 'Conference Services Manager',
  TechnicalAudioVisualManager = 'Technical Audio Visual Manager',
  // New roles - Revisit above roles during LCR refactor
  MemberExperienceAssociate = 'Member Experience Associate',
  MemberExperienceManager = 'Member Experience Manager',
  AssistantGeneralManager = 'Assistant General Manager',
  GeneralManager = 'General Manager',
  RegionalDirector = 'Regional Director',
  AreaSalesLead = 'Area Sales Lead',
  EnterpriseSales = 'Enterprise Sales',
  AccountManager = 'Account Manager',
  FacilitiesManager = 'Facilities Manager',
  FacilitiesAssociate = 'Facilities Associate',
}
